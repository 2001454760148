
img{
    width:100%;
    margin-bottom:-1.25em
}

.description{
    text-align:center;
    font-size: 14px;
    margin-bottom: 25px;
}


@media only screen and (min-width: 600px){
    img{
        width:80%;
        margin-bottom:-20px;
        margin-left:20px
    }
    
    .description{
        text-align:center;
        font-size: 24px;
        margin-bottom: 25px;
    }
    
}

@media only screen and (min-width: 992px){
    img{
        width:60%;
        margin-bottom:-20px;
        margin-left:50px
    }
    
    .description{
        text-align:center;
        font-size: 24px;
        margin-bottom: 35px;
    }
}

@media only screen and (min-width: 1200px){
    img{
        width:60%;
        margin-bottom:-30px;
        margin-left:50px
    }
    
    .description{
        text-align:center;
        font-size: 24px;
        margin-bottom: 30px;
    }
}


@media only screen and (min-height: 767px){
    img{
        width:70%;
        margin-bottom:-1.875em;
        margin-left:2.5em
    }
    
    .description{
        text-align:center;
        font-size: 16px;
        margin-bottom: 40px;
    }
}

@media only screen and (min-height: 812px){
    img{
        width:80%;
        margin-bottom:-1.875em;
        margin-left:0.9375em
    }
    
    .description{
        text-align:center;
        font-size: 16px;
        margin-bottom: 40px;
    }
}

@media only screen and (min-height: 1024px){
    img{
        width:90%;
        margin-bottom:-5px;
        margin-left:0px
    }
    
    .description{
        text-align:center;
        font-size: 24px;
        margin-bottom: 50px;
    }
}

@media only screen and (min-height: 1365px){
    img{
        width:90%;
        margin-bottom:-30px;
        margin-left:20px
    }
    
    .description{
        text-align:center;
        font-size: 24px;
        margin-bottom: 40px;
    }
}

@media only screen and (min-width: 2559px) and (min-height: 1024px){
    img{
        width:60%;
        margin-bottom:-40px;
        margin-left:100px
    }
    
    .description{
        text-align:center;
        font-size: 36px;
        margin-bottom: 30px;
    }
}
