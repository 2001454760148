.placeholder {
    background-color:#CCDBDC;
    width:95%;
    margin:10px;
    border-radius: 50px;
}

.place{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px;
}

.component{
    background-color:#80CDD7;
    margin: 5px;
    width:45%;
    border-radius: 10px;
    text-align: center;
    line-height:35px
}

.top-bar{
    text-align: center;
    background-color:#9AD1D4;
    line-height:50px;
    width:100%;
    border-radius: 50px 50px 0px 0px;
    font-size: 24px;
    font-weight: bold;
}

.label{
    text-align: center;
    font-size:16px;
    font-weight:bold;
}

.unit{

    position: relative;
}

@media only screen and (min-width: 600px){
    .placeholder {
        background-color:#CCDBDC;
        width:95%;
        margin:10px;
        border-radius: 50px;
    }
    
    .place{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 10px;
    }
    
    .component{
        background-color:#80CDD7;
        margin: 5px;
        width:45%;
        border-radius: 10px;
        text-align: center;
        line-height:35px;
        font-size:24px
    }
    
    .top-bar{
        text-align: center;
        background-color:#9AD1D4;
        line-height:50px;
        width:100%;
        border-radius: 50px 50px 0px 0px;
        font-size: 36px;
        font-weight: bold;
    }
    
    .label{
        text-align: center;
        font-size:24px;
        font-weight:bold;
    }
    
    .unit{
    
        position: relative;
    }
}

@media only screen and (min-width: 992px){
    .placeholder {
        background-color:#CCDBDC;
        width:95%;
        margin:10px;
        border-radius: 50px;
        height:90%;
    }
    
    .place.weather{
        /* background-color:forestgreen; */
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 10px;
        height: 80%;
        margin-top: 20px;
    }

    .place.polution{
        /* background-color:forestgreen; */
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 10px;
        height: 80%;
        margin-top: 10px;
    }
    
    .component.weather{
        background-color:#80CDD7;
        margin: 5px;
        width:40%;
        height:20%;
        border-radius: 10px;
        text-align: center;
        font-size:24px;
        line-height:30px
    }
    
    .component.polution{
        background-color:#80CDD7;
        margin: 5px;
        width:40%;
        height:20%;
        border-radius: 10px;
        text-align: center;
        font-size:24px;
        line-height:30px
    }

    .top-bar{
        text-align: center;
        background-color:#9AD1D4;
        line-height:50px;
        width:100%;
        border-radius: 50px 50px 0px 0px;
        font-size: 36px;
        font-weight: bold;
    }
    
    .label.weather{
        text-align: center;
        font-size:24px;
        font-weight:bold;
    }

    .label.polution{
        text-align: center;
        font-size:18px;
        font-weight:bold;
    }
}

@media only screen and (min-width: 1200px){
    .placeholder {
        background-color:#CCDBDC;
        width:95%;
        margin:10px;
        border-radius: 50px;
        height:90%;
    }
    
    .place.weather{
        /* background-color:forestgreen; */
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 10px;
        height: 80%;
        margin-top: 30px;
    }

    .place.polution{
        /* background-color:forestgreen; */
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 10px;
        height: 80%;
        margin-top: -3px;
    }
    
    .component.weather{
        background-color:#80CDD7;
        margin: 5px;
        width:30%;
        height:30%;
        border-radius: 10px;
        text-align: center;
        font-size:24px;
        line-height:45px
    }
    
    .component.polution{
        background-color:#80CDD7;
        margin: 5px;
        width:45%;
        height:23%;
        border-radius: 10px;
        text-align: center;
        font-size:24px;
        line-height:35px
    }

    .top-bar{
        text-align: center;
        background-color:#9AD1D4;
        line-height:50px;
        width:100%;
        border-radius: 50px 50px 0px 0px;
        font-size: 36px;
        font-weight: bold;
    }
    
    .label.weather{
        text-align: center;
        font-size:24px;
        font-weight:bold;
    }

    .label.polution{
        text-align: center;
        font-size:24px;
        font-weight:bold;
    }
}

@media only screen and (min-width: 2559px){
     .placeholder {
        background-color:#CCDBDC;
        width:95%;
        margin:10px;
        border-radius: 50px;
        height:90%;
    }
    
    .place.weather{
        /* background-color:forestgreen; */
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 10px;
        height: 80%;
        margin-top: 65px;
    }

    .place.polution{
        /* background-color:forestgreen; */
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 10px;
        height: 80%;
        margin-top: 15px;
    }
    
    .component.weather{
        background-color:#80CDD7;
        margin: 5px;
        width:30%;
        height:30%;
        border-radius: 10px;
        text-align: center;
        font-size:56px;
        line-height:70px
    }
    
    .component.polution{
        background-color:#80CDD7;
        margin: 5px;
        width:45%;
        height:23%;
        border-radius: 10px;
        text-align: center;
        font-size:56px;
        line-height:50px
    }

    .top-bar{
        text-align: center;
        background-color:#9AD1D4;
        line-height:50px;
        width:100%;
        border-radius: 50px 50px 0px 0px;
        font-size: 48px;
        font-weight: bold;
        padding:20px 0px ;
    }
    
    .label.weather{
        text-align: center;
        font-size:36px;
        font-weight:bold;
    }

    .label.polution{
        text-align: center;
        font-size:36px;
        font-weight:bold;
    }
}